/* to change color of button on hover */
/* .outer {
    margin: 50px;
  } */
  
  .button {
    border: 1px solid goldenrod;
    border-radius: 4px;
    width: 10rem;
    height: 3rem;
    padding-top: 5px;
    display: block;  
    background: linear-gradient(to right, black 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
  }
  
  .button:hover {
    background-position: left bottom;
    color: white;
  }
  
  .text {
    text-align: center;
    font-size: 1.5rem;
    line-height: 30px; 
    color: rgb(233, 170, 13);
    font-weight: bold;
    transition: all .6s ease-out;
    display: block;
  }
  
  .text:hover {
    color: white;  
  }

  
    /* to side up the element */
    .slideUp{
        position: relative;
        top: 0;
        transition: top ease 0.5s;
    }
    .slideUp:hover {
        top: -10px;
        }
    /* to slide right  */
        .slideRight{
          position: relative;
          right: 0;
          transition: right ease 0.5s;
      }
      .slideRight:hover {
          right: -10px;
          }
    /* to rotate the image */
    .tiltImage{
        -ms-transform: rotate(-5deg); /* IE 9 */
        transform: rotate(-5deg);  
        position: relative;
        -webkit-transition: -webkit-transform .8s ease-in-out;
        transition: transform ease .5s;
    }
    .tiltImage:hover{
        animation: rotation 2s infinite linear;
    }
    @keyframes rotation{
        from{
            transform: rotate(0deg);
        }
        to{
            transform: rotate(360deg);
        }
    }
    .contentText{
      font-size: 15px;
  }
.iconMoving{  
  position: relative;
  top: 0px;
  animation: 2s ease-in-out infinite;
}
.iconMoving:hover{
  top: -10px;
}
    @media screen and (max-width: 600px) {
      .contentText{
          font-size: 15px;
      }

    }


    
  /* background-color: linear-gradient(rgb(29, 19, 167), rgba(25,156, 0, 1) ); */
  /* box-shadow: 0px 3px 16px -10px rgb(0 0 0 / 75%); */
  /* background-color: rgb(243, 239, 239); */
  /* managed it service */
/* .techoBox{
  color: white;
  padding: 1rem;
  height: fit-content;
  border-right: 4mm ridge rgba(39, 8, 151, 0.6);
  border-left: 0 solid transparent;
  background-color: rgb(11, 121, 248);    
  width: 70%;
  box-sizing: padding-box!important;
  position: relative;
  bottom: 10px;
  margin-left: 4rem;

}
.techoBox::before{
  content:"";
  position: absolute;
  right: 100%;
  top:0px;
  width:0px;
  height:0px;    
  border-top: 3rem solid rgb(11, 121, 248);
  border-left: 3rem solid transparent;
}
.tech-supported-box{
  width: 20rem;
  height: 80%;
  background-color: white;
  box-sizing: border-box!important;
  margin: 2rem;    
  display: flex;
  padding: 1.5rem 1rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
 
  padding-left: 4rem;
  box-shadow: 12px 12px rgba(53, 86, 194, 0.8) ;
} */


/* back filter box */
/* .blur-back{
    width: 100%;
    height: 25rem;
    background-image: url("/public/Images/engin.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
    margin-bottom: 5rem;
    padding: 5%;
    z-index: 1;
} 
 .modalBox {
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 5px;
  color: rgb(14, 5, 5);
  font-family: sans-serif;
  line-height: 1.5;
  max-width: 80%;
  height: fit-content;
  padding: 1rem 2rem;
  margin: 2rem;
  display: inline-flex;
  overflow: hidden;
  z-index: 3;
  position: relative;
  outline: 0;
}
.modalBox a {
  color: #bf0222;
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .modalBox {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.5);
  }  
  .warning {
    display: none;
  }
} */

/* .root{
  max-width: 100%;
  flex-grow: 1;      
}
.root .header{
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: .5rem;
  background-color: antiquewhite;
} */

/* ooverlapping */
/* .overlap-box{
  width: 90%;
  height: 50%;
  padding: 3rem;
  margin: 4rem;
  color: rgb(7, 6, 102);
  box-sizing: border-box;
  font-weight: 700;
  font-family: Georgia, 'Times New Roman', Times, serif;
  background-color: white;
  box-shadow: 10px 20px 20px 0px rgba(181, 181, 184, 0.7);
}
**for media query = 400px
.overlap-box{
  width: 80%;
  height: 12rem;
  padding: 1rem;
  padding-top: 0;
  margin: 1.5rem;
  margin-bottom: 2rem;
  color: rgb(7, 6, 102);
  box-sizing: border-box;
  font-weight: 500;
  box-shadow: 10px 10px 10px 0px rgba(181, 181, 184, 0.7);        
} */