
#hexagon {
    margin-top: 10%;
    color: white;
    width: 100px;
    height: 57.735px;
    background: rgb(255, 100, 100);
    position: relative;
    text-align: center;
    
  }
  #hexagon::before {
    content: "";
    position: absolute;
    top: -28.8675px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 28.8675px solid rgb(255, 100, 100);
  }
  #hexagon::after {
    content: "";
    position: absolute;
    bottom: -28.8675px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-top: 28.8675px solid rgb(255, 100, 100);
  }

  
.arrow-left {
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;     
  border-left:10px solid rgb(207, 84, 12); 
}
.triangle-bottom{
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-top: 70px solid rgb(6, 6, 250);
}
.triangle-bottomleft{
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-left: 50px solid blue;
}
.triangle-topleft{
    width: 0;
    height:0;
    border-top: 100px solid transparent;
    border-right: 100px solid rgb(15, 128, 221);
}
.triangle-topright {
  width: 0;
  height: 0;
  border-top: 3rem solid rgb(11, 121, 248);
  border-left: 3rem solid transparent;
  position: absolute;
}


