.whoweare{
    background-color: #e9e7e1;
    padding: 40px;
}

.heading{
font-size: 24px;
    color: #252b33;
    font-weight: 600;
    line-height: 34px;
    margin-bottom: 10px;
}

.count{
    padding: 25px;
    color:rgb(87, 83, 83);
    font-size: 40px;
    font-weight: bolder;
}

