
/*
--------------------------
	Css Indexing
--------------------------
** @typography
--------------------------
**  APPLICATION Modernization css= done
--------------------------
** Automation Service css = done
--------------------------
** Managed service css = done
-------------------------- 
** ODM service css
-------------------------- 
** Watson service css
-------------------------- 
** @media queries css
-------------------------- 
 */

/*  Managed service css */
.managed-banner{
    background-image: url('/public/Images/300/Managed-Services.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    color: white;
    padding: 40px 30px;
}
.right-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}
.itp{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-shadow: 3px 8px 8px rgb(201, 203, 206) ;
    text-emphasis-color: rgb(179, 121, 121);
}
.right-container p{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 30px;
    width: 25vw;
    line-height: 30px;
    position: absolute;
    top: 25%;
    left: 20%;
    text-shadow: 3px 8px 8px rgb(201, 203, 206) ;
    font-weight: 600;
    /* background-color: #1E2832; */
}
.keyTechBox{
    width: 100%;
    height: 100%;
    max-height: 100%;
    background-image: url('/public/Images/1000/digital1.jpg');
    background-size: cover;      
    background-attachment: fixed;
    background-repeat: no-repeat;
}
.text-box{
    display: flex;
    justify-content: center;
    align-items: center;
    color:#0d680a;
}

/*  Application Modernization service css */
.first-container{
    color:rgb(128, 129, 131);
    margin-top: 3rem;
}
.first-container p{
    padding: 15px;
}
.docker-container{
    padding-top: 5%;
}
.adv-container .card{
    padding: 20px;
    /* color:rgb(72, 115, 194); */
    width: 80%;
}
.adv-container .card:hover{
    box-shadow: 0px 0px 20px rgb(190, 186, 186);    
    /* border: 3px solid rgb(9, 118, 243); */
}
.services-content a{
    font-weight: 600;
}
.services-content a:hover{
    text-decoration: none;
}

.app-image{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(12, 6, 92, 0.7)), url("/public/Images/300/cloud-computing.png");
    background-repeat: no-repeat;
    background-position: right;
    display: flex;
    align-items: center;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    width: 100%;
    height: 445px;
}

/* Automation Service css */
.highlightsWrapper{
    width: 100%;
    height: auto;
    text-align: center;
    padding: 1rem;
}
.highlightsBox{
    height: 13rem;
    width: 80%;
    padding: 5% ;
    background-color: white;
    box-shadow: inset 0px 0px 20px 0px rgb(82, 72, 170);
    box-sizing: content-box;    
    color: rgb(190, 11, 56);
}
.contact-bg{
    background-color: linear-gradient(rgb(102, 162, 185), rgb(224, 83, 125));
}
.iconBox{
    margin-left: 4rem;
    margin-bottom: 20px;
    width: fit-content;
    height: fit-content;
    padding: 1%;
}
.resellerBox{
    background-image: url('/public/Images/300/comp-chip.jpg');
    background-size: cover;
    z-index: 1;
    color: white;
    margin: 2rem;
    padding: 4rem;
}
@media screen and (max-width: 400px) {
    .offeringPaading{
        padding: 10px;
    }    
    .buildBox{
        width: 100%;
        height: auto;
        padding: 15px;
        background-color: #fff;
    }
    .offeringBox{
        width: 100%;
        height: 100%;
        padding: 10px;
    }
  
}
@media screen and (max-width: 1400px) {


}