/* 

CSS INDEXING
--------------------------
** Global CSS
--------------------------
*/

  /* ---------------------------------------- for all grid ---------------------------------------------------- */

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12{
	padding: 0px;
}
.col-md-2,.col-md-3, .col-md-4, .col-md-5,.col-md-6, .col-md-7, .col-md-12, .col-md-8, .col-md-9, .col-md-10 {
	padding: 0px;
} 
.col-md{
	padding: 0px;
}

  /* remove arrow spinner for number type input Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
  }

  /* ---------------------------------------- for all grid ---------------------------------------------------- */

*{padding: 0; margin: 0; box-sizing: border-box;}

 *::before, *::after {
    box-sizing: border-box;
}
  
/* .service-section {
	padding: 15px;
} */
/* Home Offerings  */
/* main .service-section .service-carousel .row {
	margin-right: 0px;
	margin-left: 0px;
}
main .service-section .container .service-carousel .row .col-md-3 .col-md-4{
	position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
} */


  
  /* ---------------------------------------- custom card ---------------------------------------------------- */
.serviceAction:hover{
	text-decoration: none;
}
#offering-card:hover{
	box-shadow: 0px 0px 10px 10px rgb(207, 203, 203);	
}
.offering-over:hover h5{
	color: rgb(9, 79, 230);
}

.line-clamp {
	/* overflow: scroll; */
	overflow-y: auto;
    /* display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;   */
	/* --max-lines: 5;
	position: relative;
	max-height: calc(var(--lh) * var(--max-lines));
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 1rem; space for ellipsis */
  }
  /* ---------------------------------------- custom card ---------------------------------------------------- */

  /* ---------------------------------------- carousels ---------------------------------------------------- */

.car-box{
	position: relative;
	width: 100%;
	height: 28rem;
	top: 0px;
    background-position: top;
}
#hero-area .contents{padding:160px 0 210px}
#hero-area .contents .head-title
	{
		color:#fff;font-size:50px;font-weight:700;text-transform:uppercase;margin-bottom:15px;
	}
.odm-bg{
	background-image: url('/public/Images/300/odm.png') no-repeat;
	background-size: cover;
}
.quote-bg2{
	background-image: url('/public/Images/300/odm.png');
	background-size: cover;
}

.content-wrapper{
    padding-top: 3rem;
	color: #000;
	position: relative;
	width: 100%;
	height: 100%;
}
.subcontent-wrapper{
    padding: 3rem;
	line-height: 3px;
}
.avps-container{
	padding: 4rem;
	background-color: #333;
	position: relative;
	color: white;
}
.services-wrapper{
	/* margin: 5vw; */
	/* padding: 0px; */
	width: 100%;
	height: 100%;
	color: rgb(243, 239, 239, .8);
	margin: 0px;
}
.service1-box{
	background: linear-gradient(to right, #000 50%, white 90%, transparent 80%);
	/* background-color: #000; */
	padding: 3rem;
	width: 100%;
	height: 100%;
	margin: 0px;

}
.reseller-font{
	font-weight: 200;
	line-height: 2em;
	/* font-display: unset; */
	font-size: 4em;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
	text-transform: capitalize;
	color: white;
}
			
.upperMargin{
	margin-top: 4.5rem;
}
.highlight-wrapper{
	padding: 2rem;
	background-color: rgba(212, 206, 206, 0.5);
	width: 100%;
	height: 100%;
}
 
  /* -------------------------------------------------------------------------------------------- */
.banner {
    position: relative;
	text-align: center;
	width: 100%;
	height: auto;
	background-size: cover;
	background-attachment: fixed;
	position: relative;
	overflow: hidden;
	border-radius: 0 0 85% 85% / 30%;
}
.banner .overlay{
	width: 100%;
	height: 60%;
	padding: 25px;
	color: #FFF;
	text-shadow: 1px 1px 1px #333;
  background-image: linear-gradient( 135deg, #9f05ff69 10%, #fd5e086b 100%);
	
}

.img-color {
	/* background: #3f51b5; */
	background-size: cover;
	z-index: 1;
	float: right;
}
.img-color .img {
	/* background: linear-gradient(to right, black ,red,  transparent); */
	object-fit: cover;
	z-index: 2;
}
h3, p {
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 30px;
}

/* h2{ */
	/* animation-name: example;
	animation-duration: 6s;	 */
/* } */
  
.centered {
	color: rgb(87, 81, 81);
	position: absolute;
	top: 25%;
	left: 18%;
	display: block;
	}
.box{
	height: 25rem;
	width: 100%;
}

.img-box{
	height: 100%;
	width: 100%;
	position: relative;
	top: 0;
}

.img-right{
	height: 100%;
	z-index: 1;
	width: 100%;
	float: right;
	position: relative;
	background-color: linear-gradient(to right, black, transparent);
	bottom: 0px;
	right: 0px;
	background-size: cover;
	background-repeat: no-repeat;
}
.img-blur{
  background:url('/public/Images/CarouselImages/400/personalization.png');
  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);  
  /* Full height */
  height: 100%; 
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.img2{
	width: 100%;
	height: 100%;
	background-position: left;
	background-repeat: no-repeat;
	/* margin-left: 3rem; */
	background-size: contain;
	opacity: 0.3;
	background-image: url('/public/Images/CarouselImages/1000/pak-system.png');
}
.img-left{	
  height: 100%;
  background: linear-gradient(to left , black 55%, transparent);
  float: right;
  width: 100%;
  max-width: 100%;
  box-sizing: content-box!important;
border-left: 0 solid transparent;
position: relative;
display: flex;
justify-content: end;

/* align-items: flex-end; */
}
.centreImg{	
	height: 100%;
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right;
	/* background-color: rgb(27, 54, 172); */
	background-image: url('/public/Images/CarouselImages/1000/cloud-migration.png');
	opacity: 0.5;
	/* box-shadow: inset 0px 0px 10px 10px rgba(248, 244, 244, 0.7); */
	box-sizing: content-box!important;
	border-left: 0 solid transparent;	
}
/* .makeStyles-typo-2:hover {
    color: rgb(9, 79, 230)
} */
.img::before{
	content:"";
    position: absolute;
    right: 100%;
	box-shadow: inset 0px 0px 30px 30px rgba(248, 246, 246, 0.9);
    top:0px;
    width:0px;
    height:0px;  
	border-left: 200px solid transparent;
	border-top: 300px solid rgb(4, 4, 5);
}
/* Position text in the middle of the page/image */
.bg-text {
	background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
	color: white;
	font-weight: bold;
	border: 3px solid #f1f1f1;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	width: 50%;
	padding: 20px;
	text-align: center;
  }

/* .img-color {
	border-radius: 0 0 85% 85% / 30%;
} */
.img-right .img {
	object-fit: cover;
	z-index: 2;
}
.fontstyl{	
	/* font-family: 'IBM Plex Sans',ibm-plex-sans,'Helvetica Neue',Arial,sans-serif; */
	font-size: 1rem;
	line-height: 1.5rem;
	padding: 1.5rem !important;
	color: rgb(0,0,0, 0.7);
}

.boxpaddin{
	position: relative;
	padding: 2rem;
	height: 20rem;
	width: 100%;
	/* margin-right: 20px; */
	background-color: white;
	overflow: auto;
}
.boxpaddin h1{
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
/* .textpadding{
	padding: 2rem;
} */
.wow{
	visibility: hidden;
}
.boxouter{
	position:absolute;
	margin: 2rem;
	padding: 2rem;
	color: #fff;
	width: 100%;
	/* background: -moz-linear-gradient(top, #1e5799 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%); */
	/* opacity: .7; */
	z-index: 2
}
.boxinner{
	font-size: 50px ;
	text-transform: capitalize;
	font-weight: 700;
	line-height: 1.5em;
	/* margin: 3rem; */
	padding: 3rem;
	width: 50%;
	z-index: 2;
	text-align: left;
}
.titleBox{
	font-size: 22px;
	text-transform: lowercase;
	text-align: right;
	font-style: italic;
	z-index: 2;
}

/* #carousel-area .contents{
	padding:160px 0 210px}
#carousel-area .contents .head-title{
		color:#fff;font-size:50px;font-weight:700;text-transform:uppercase;margin-bottom:15px}
#carousel-area .contents span.year{
	color:#00bcd4}
#carousel-area .contents p{
		font-size:16px;color:#fff;font-weight:400;line-height:26px} */

.companyName{
  font-size: 5vw;
}

.quotes{
  font-size: 1.5vw;
}

.box{
	height: 415px;
	width: 100%;
	background-color:white;	
}
.box .curveBox{
	
	background-image: linear-gradient(90deg,rgb(185, 178, 178), rgb(129, 129, 116));
	height: 415px;
	width: 100%;
}

@keyframes example {
	from {
	color:black; 
letter-spacing: 25px;

}
	to {
	color: rgb(223, 43, 43);
letter-spacing: normal;

}
  }

  .inverted-bar {
	position: relative; 
	width: 100%;
}

.inverted-bar:before,
.inverted-bar:after {
	padding-left: 20%;
	padding-top: 10%;
	padding-bottom: 15%;
	text-indent: 50px;
	position: absolute;
	white-space: nowrap;
	overflow: hidden;
	content: attr(data-content);
	font-size: 5vw;     
}
	
.inverted-bar:before {
	background-color: white;
	color: rgb(223, 43, 43);
	width: 100%;
}

.inverted-bar:after {
	background-color: rgb(223, 43, 43);
	color: white;
	width: 50%;
}

.inverted-bar2 {
	position: relative; 
	width: 100%;
}

.inverted-bar2:before,
.inverted-bar2:after {
	padding-left: 20%;
	padding-top: 10%;
	padding-bottom: 15%;
	text-indent: 50px;
	position: absolute;
	white-space: nowrap;
	overflow: hidden;
	content: attr(data-content);
	font-size: 5vw;     
}
	
.inverted-bar2:before {
	background-color: white;
	color:rgb(16, 14, 161);
	width: 100%;
}

.inverted-bar2:after {
	background-color: rgb(16, 14, 161);
	color: white;
	width: 50%;
}


.inverted-bar3 {
	position: relative; 
	width: 100%;
}

.inverted-bar3:before,
.inverted-bar3:after {
	padding-left: 20%;
	padding-top: 10%;
	padding-bottom: 15%;
	text-indent: 50px;
	position: absolute;
	white-space: nowrap;
	overflow: hidden;
	content: attr(data-content);
	font-size: 5vw;     
}
	
.inverted-bar3:before {
	background-color: white;
	color:rgba(10, 128, 6, 0.945);
	width: 100%;
}

.inverted-bar3:after {
	background-color: rgba(10, 128, 6, 0.945);
	color: white;
	width: 50%;
}
  

.inverted-bar:before,
.inverted-bar:after {
	padding-left: 20%;
	padding-top: 10%;
	padding-bottom: 15%;
	text-indent: 50px;
	position: absolute;
	white-space: nowrap;
	overflow: hidden;
	content: attr(data-content);
	font-size: 5vw;     
}
	
.inverted-bar:before {
	background-color: white;
	color:  rgb(223, 43, 43);
	width: 100%;
}

.inverted-bar:after {
	background-color:  rgb(223, 43, 43);
	color: white;
	width: 50%;
}
	

	
/* effect-2 styles */
.buttons-container {
	width: 100%;
	margin: 20px auto 0px auto;
	/* background: burlywood; */
	text-align: center;
  }
.buttons-container .button-effect {
  padding: 10px 0px;
}
/* .buttons-container .button-effect h2 {
  font-family: "Droid Serif", serif;
  font-size: 20px;
  margin-bottom: 10px;
} */

.buttons-container .button-effect a {
	margin-right: 17px;
  }
.buttons-container .button-effect a:nth-child(2) {
	background-color: #541388;
  }
  
.buttons-container .button-effect a:last-child {
	margin-right: 0px;
  }
  .effect {
	text-align: center;
	display: inline-block;
	position: relative;
	text-decoration: none;
	color: #fff;
	text-transform: capitalize;
	/* background-color: - add your own background-color */
	background-color: #541388;
	font-family: "Roboto", sans-serif;
	/* put your font-family */
	font-size: 18px;
	padding: 10px 0px;
	width: 150px;
	border-radius: 6px;
	overflow: hidden;
  }
.effect.effect-2 {
	transition: all 0.2s linear 0s;
  }
	.effect.effect-2:before {
	content: "";
	color: white;
	font-family: FontAwesome;
	font-size: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	right: -30px;
	width: 30px;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.3);
	border-radius: 0px 6px 6px 0px;
	transition: all 0.2s linear 0s;
	text-align: center;
	}
  .effect.effect-2:hover {
	text-indent: -30px;
	text-decoration: none;
	color: white;

  }
  .effect.effect-2:hover:before {
	right: 0;
	text-indent: 0px;
  }
  #resume{
	  display: none;
  }

   /* ------------------------------------------SCREEN SIZES-------------------------------------------------- */
 	@media only screen and (max-width: 868px) {
		
	}
	@media screen and (max-width: 900px){
		/* .navbar-container{	
			display: flex;
			flex-wrap: wrap;
		    justify-content: space-between;
			width: 100%;
		  margin-right: 10%;
		  margin-left: 10%;
		} */
		
		.service1-box{
			background: linear-gradient(to right, #000 40%, white 90%, transparent 80%);
			/* background-color: #000; */
			padding: 3rem;
			width: 100%;
			height: 100%;
			margin: 0px;

		}
		.boxouter{
			position:absolute;
			margin: 2rem;
			padding: 2rem;
			color: #fff;
			width: 100%;
			/* background: -moz-linear-gradient(top, #1e5799 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%); */
			/* opacity: .7; */
			z-index: 2
		}
		.boxinner{
			font-size: 50px ;
			text-transform: capitalize;
			font-weight: bold;
			/* margin: 3rem; */
			padding: 3rem;
			width: 50%;
			z-index: 2;
			text-align: left;
		}
		.titleBox{
			font-size: 22px;
			text-transform: lowercase;
			text-align: right;
			font-style: italic;
			z-index: 2;
		}
	
	}
	@media screen and (max-width: 600px) {
	
		.inverted-bar {
			position: relative; 
			width: 100%;
		}
		.centered {
			color: rgb(87, 81, 81);
			position: absolute;
			top: 25%;
			left: 25%;
			display: block;
		}			
		.box{
			height: 100%;
			width: 100%;
			/* background-color:white; */
		}
		.box .curveBox{	
			background-image: linear-gradient(90deg,rgb(185, 178, 178), rgb(129, 129, 116));
			height: 115px;
			width: 100%;
		}
	}
	@media screen and (max-width: 500px) {
		.car-box{
			position: relative;
			width: 100%;
			height: 20rem;
			top: 0px;
			background-position: top;
		}
		.service1-box{
			background: #000;
			/* background-color: #000; */
			padding: 3rem;
			width: 100%;
			height: 100%;
			margin: 0px;

		}
		.centered {
			color: rgb(87, 81, 81);
			position: absolute;
			top: 25%;
			left: 25%;
			display: block;
		}			
		.box{
			height: 100%;
			width: 100%;
			/* background-color:white; */
		}
		.box .curveBox{	
			background-image: linear-gradient(90deg,rgb(185, 178, 178), rgb(129, 129, 116));
			height: 115px;
			width: 100%;
		}
		
		.boxouter{
			position:absolute;
			margin: 2rem;
			padding: 2rem;
			color: #fff;
			width: 100%;
			/* background: -moz-linear-gradient(top, #1e5799 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%); */
			/* opacity: .7; */
			z-index: 2
		}
		.boxinner{
			font-size: 20px ;
			text-transform: capitalize;
			font-weight: bold;
			/* margin: 3rem; */
			padding: 3rem;
			width: 50%;
			z-index: 2;
			text-align: left;
		}
		
	
		#hero-area .contents{padding:100px 0 120px}
		#hero-area .contents .head-title{color:#fff;font-size:20px;font-weight:600;
			}
		/* #hero-area .contents span.year{color:#00bcd4} */
	
		.titleBox{
			font-size: 22px;
			text-transform: lowercase;
			text-align: right;
			font-style: italic;
			z-index: 2;
		}
		
	}
	@media screen and (max-width: 1000px) and (min-width: 900px) {
		
	}

	@media screen and (min-width: 1000px) and (max-width: 1400px){
		/* .navbar-container{	
			display: flex;
			flex-wrap: wrap;
		    justify-content: space-between;
			width: 100%;
		}	 */
	.upperMargin{
		margin-top: 6vw;
	}
	.avps-container{
		padding: 3rem;
	}
	.boxouter{
		position:absolute;
		margin: 2rem;
		padding: 2rem;
		color: #fff;
		width: 100%;
		/* background: -moz-linear-gradient(top, #1e5799 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%); */
		/* opacity: .7; */
		z-index: 2
	}
	.boxinner{
		font-size: 50px ;
		text-transform: capitalize;
		font-weight: bold;
		/* margin: 3rem; */
		padding: 3rem;
		width: 50%;
		z-index: 2;
		text-align: left;
	}
	.titleBox{
		font-size: 22px;
		text-transform: lowercase;
		text-align: right;
		font-style: italic;
		z-index: 2;
	}
	#carousel-area{
		/* background:url(/public/Images/banner/gif/half.gif) no-repeat; */
		background-size:cover;
		color:#fff;
		overflow:hidden;
		position:relative}
	
	#carousel-area .overlay{
		content:'';
		position:absolute;
		top:0;left:0;width:100%;
		height:100%;
		background:rgba(54,59,77,.88)}
		
	}
