
.fontcontent{
    font-size: 20px;    
}
.fontcontent2{
    font: 500;
    font-size: 30px;    
}
.fonttitle{
    font-size: 40px;
    padding: 20px;
}
.fontcontent3{
    font-size: 30px;    
}
.fontdisplay{
    font-size: 2rem;
    /* padding: 20px; */
    font-weight: bold;
    z-index: 2;
    text-justify: distribute;
}
.fontdisplay-1{
    font-size: 6rem;
    line-height: 1.2;
    /* padding: 20px; */
    font-weight: 300;
}
.fontdisplay-2{
    font-size: 5.5rem;
    /* padding: 20px; */
    line-height: 1.2;
    font-weight: 300;
}
.fontdisplay-3{
    font-size: 4.5rem;
    /* padding: 20px; */
    line-height: 1.2;
    font-weight: 300;
}
.fontdisplay-4{
    font-size: 3.5rem;
    line-height: 1.2;
    /* padding: 20px; */
    font-weight: 300;
}


@media screen and (max-width: 400px) {
   
    .fonttitle{
        font-size: 20px;
        padding: 10px;
    }
    .fontcontent{
        font-size: 15px;        
    }
    .fontcontent2{
        font-size: 20px;        
    }
    .fontcontent3{
        font-size: 20px;
        
    }
    .fontdisplay{
        font-size: 2rem;
        /* padding: 10px; */
    }
    .fontdisplay-1{
        font-size: 3.5rem;
        line-height: 1;
        font-weight: 300;
        padding: 10px;
    }
    .fontdisplay-2{
        font-size: 3rem;
        padding: 10px;
        line-height: 1;
        font-weight: 300;
    }
    .fontdisplay-3{
        font-size: 2.5rem;
        padding: 10px;
        line-height: 1.2;
        font-weight: 700;
    }
    .fontdisplay-4{
        font-size: 2rem;
        padding: 10px;
        line-height: 1;
        font-weight: 300;
    }

}

@media screen and (max-width: 900px) and (min-width: 450px) {
    .fontcontent{
        font-size: 20px;
    }
    .fontcontent3{
        font-size: 20px;
    }
    .fontdisplay-4{
        font-size: 2.5rem;
    }

}