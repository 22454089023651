.banner1{
    background:linear-gradient(to right, rgba(0,0,0) 30%, transparent), url('/public/Images/CarouselImages/ibm2.webp');
    width: 100%;
    height: 100%;
    float: right;
    position: relative;
    background-position: right;
    background-repeat: no-repeat;
    right: 0;

    /* width:'100%', height:'100%', float: 'right',position:'relative', right:'0',  
    backgroundPosition: 'right', backgroundRepeat:'no-repeat' */

}