.contactBox{
    background-image: linear-gradient( black,transparent), url('/public/Images/contactus/mouse.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    max-height: auto;
    max-width: 400px;
    /* max-width: 345; */
    height: 100%;
    width: 100%;
    /* color: white; */
    padding: 3rem;
    /* opacity: 0.7s; */
}
.contact-title{
    font-size: 30px;
    padding: .5rem .5rem;
    font-weight: 500;
}
@media screen and (max-width: 800px) {
    .contactBox{        
        /* background-image: linear-gradient(to bottom, black,transparent), url('/public/Images/sales.jpg'); */
        height: 100%;
        width: 100%;
        margin: 0;
        /* padding: 1rem; */
    }
  
    .contact-title{
        font-size: 35px;
        font-weight: 500;
        padding: 1rem 1rem;
    }
}
@media screen and (max-width: 400px) {
    .contact-title{
        font-size: 30px;
        font-weight: 400;
        padding: 1rem 1rem;

    }
}