
.bannerImg{
 background-image:
    linear-gradient(to right, rgba(6, 6, 7, 0.911) 30%, rgba(117, 19, 93, 0.73)),
        url('/public/Images/1000/digital3.jpg');
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 100%;
    color: white;
    /* padding: 5rem; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.aboutBox{
    padding: 6%;
    background-color: #fafafa;
    height: 100%;
}
.aboutBox:hover{
    box-shadow: 2px 2px 25px #a3a4e9;
}
.whatWeDo{
    margin-top: 5%;
    margin-bottom: 5%;
    width: 100%;
    height: 100%;
    
}
.whatWeDoBox{
    /* width: 100%; */
    /* padding: 0; */
    /* margin: 0; */
    background-color: rgba(62, 60, 65, 0.856);
}
.boxImage{
    background-image: url('/public/Images/300/hands.jpg');
    width: 100%;
    height: 100%;
    padding: 3rem;
    margin: 1rem;
    background-repeat: no-repeat;
    background-size: cover;
}
.techCards{
    height: 12rem;
    background-color: rgba(16, 112, 202, 0.795);
    margin: 1rem;
    width: 100%;
}
.contentWrapper{
    width: 100%;
    height: 60%;
    padding: 2rem;
    margin: 1rem;

}
.points{
    width: 100%;
    height: 100%;
    margin-left: 4rem;
}

.expertise{
    margin-top: 5%;
    margin-bottom: 5%;
    text-align: center;
}

.expertise ul{
 margin-left: 2%;   
}
.expertise ul li{
    text-align: left;
}
.expertiseBox{
    padding: 4%;
    
}
.expertiseBox:hover{
    box-shadow: 2px 2px 25px #a3a4e9; 
}
.button{
    margin-top: 20px;
    margin-bottom: 20px;
}
.btn-custom{
    position: relative;
    background-color: rgb(223, 43, 43);
    padding: 10px;
    border-radius: 20px;
    text-decoration: none;
    color: white;
    font-size: 400;
}
.btn-custom:hover{
    position: relative;
    background-color: rgb(49, 41, 41);
    padding: 10px;
    border-radius: 20px;
    color: white;
    text-decoration: none;
    font-size: 400;
}
.title{
    /* border-right: 1rem solid #091294d5;
    border-left: 1rem solid #091294d5; */
    padding: 1rem;
    margin-top: 2rem;
    position: relative;
    font-weight:bold;
    color: rgba(0, 0, 255, 0.692);
    text-align: center;
    font-size: 3em;
}
.approvedContent{
    color: white;
    font-size: 18px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    transform: rotate(-20deg);
}
.approvedBox{
    padding: 2.5rem;
    height: 15rem;
    width: 15rem;
    position: relative;
    top: 0;
    transition: top ease 0.5s;
    margin: .5rem;
    margin-bottom: 1rem;
}
.approvedBox:hover{
    top: -10px;
}
.aboutResellerBox{
    /* background-color: rgba(128, 128, 128, 0.596); */
    background-color: rgba(190, 194, 196, 0.705);
    height: 100%;
    width: 100%;
    z-index: 1;
    /* margin: 2rem; */
    padding: 4rem;
    /* padding: 2rem; */
}
.module-border-wrap {    
    max-width: 100%;
    padding: .5rem;
    position: relative;
    background: linear-gradient(to right, red, purple);
    /* padding: 3px; */
  }
  
/* .contentBox{    
    color: white;
    padding: 2rem;
    border-image-source: linear-gradient(-45deg, #743ad5, #d53a9d);
    background-color: rgb(250, 250, 250);
    height: 18rem;
    box-shadow: 2px 2px 25px #a3a4e9;
    border-radius: 20px;
} */
/* .contentBox img:hover{ */
    /* animation: rotation 2s infinite linear; */
    /* transform: rotate(10deg); */
/* } */


.contentBox img{
    box-shadow: 2px 2px 20px #a3a4e9;
    position: relative;
}

@keyframes rotation{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(45deg);
    }
}
/* .aboutBox:hover{
    box-shadow: 2px 2px 25px #a3a4e9;
} */
.content{
    font: 700;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: rgba(0, 0, 0, 0.973);
    /* display: flex;
    justify-content: center; */
    /* line-height: 2cm; */
}

.domains{
    border-bottom: 4mm ridge  ;

}
.wedo{
    font-size: 75px;
    text-align: center;
    font: optional;
    color:white;
    padding: 2rem;
    padding-top: 4rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    background: radial-gradient(to right ,black 60%, white 40%);
    /* background-color: #f14805d5; */
    height: 25rem;
    width: 25%;
}

    @media screen and (max-width: 400px) {
        .approvedContent{
            color: white;
            font-size: 15px;
            font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
            transform: rotate(-20deg);
        }
    }