.navbar-container{	
    flex-wrap: wrap;
	display: flex;
	width: 100%;
  margin-right: 10%;
  margin-left: 10%;
}

.nav-trans{
	background-color: white;	
	/* background-color: transparent; */
  }
.navbar-dark .navbar-nav .nav-item .nav-link{
    /* position: fixed; */
    /* color: rgba(0, 0, 0, 0.658); */
    color: rgba(15, 13, 13, 0.9);

}
.navbar-dark .navbar-nav .nav-item .nav-link:hover{
    color: rgb(10, 99, 233);
    border-bottom: thick double rgb(10, 99, 233);
}
.navbar-img{
max-height: 200px;
height: 100%;
width: 100%;
max-width: 200px;
position: relative;
display: flex;
align-items: center;
padding: 0 .5rem;
margin-left:.5rem ;
margin-right:.5rem ;
}
.right{
display: flex;
position: relative;
right: 0;
bottom: 0;
padding-left: 15%;
flex-direction: column;
/* flex-grow: initial; */	
}
.nav-right{
display: flex;
align-items: flex-end;
justify-content:space-evenly;
padding-bottom:.5rem ;
font-size: 15px;
text-transform: uppercase;
width: 90%;
font-weight: 400;
}
.navbtn{
display: flex;
align-items: center;
}
.navbtnlink{
border-radius: 4px;
/* background: #00bcd4; */
background-color: rgb(10, 99, 233);
padding: 10px 22px;
color: #fff;
border: none;
-webkit-text-decoration: none;
outline: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;
font-weight: 600;
}
.navbtnlink:hover{
transition: all 0.2s ease-in-out;
background: black;
text-decoration: none;
color: #fff;
}
  /* .dropdown-menu{
	  border: 0px;
  } */
  /* .navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active{
	color: rgba(15, 13, 13, 0.7);
  } */
  /* .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 14px;
    color: #fff;
    padding: 0 20px;
    letter-spacing: .5px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 30px;
    line-height: 37px;
    text-transform: uppercase;
    background: 0 0;
    font-weight: 400;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
} */
.dropdown-toggle::after {
    display: none;
}
.dropdown:hover .dropdown-menu {
    display: block;
    /* position: absolute; */
    text-align: left;
    /* top: 100%; */
    animation: fadeIn .4s;
    -webkit-animation: fadeIn .4s;
    -moz-animation: fadeIn .4s;
    -o-animation: fadeIn .4s;
    -ms-animation: fadeIn .4s;
    background: #fff;
}
.dropdown-toggle:hover {
    display: inline-block;
}
/* .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
} */
.dropdown-menu:before {
    position: absolute;
    top: -8px;
    left: 22px;
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 8px;
    border-color: transparent transparent #fff;
    box-sizing: border-box;
}
.dropdown-menu{
      margin: 0;
      padding: 10px;
      display: none;
      position: absolute;
      z-index: 99;
      min-width: 190px;
      border: none;
      background-color: #fff;
      border: 1px solid white;
      white-space: nowrap;
      border-radius: 4px;
      -webkit-box-shadow: 0 0 25px 0 rgb(0 0 0 / 8%);
      -moz-box-shadow: 0 0 25px 0 rgba(0,0,0,.08);
      box-shadow: 0 0 25px 0 rgb(0 0 0 / 8%);
      animation: fadeIn .4s;
      -webkit-animation: fadeIn .4s;
      -moz-animation: fadeIn .4s;
      -o-animation: fadeIn .4s;
      -ms-animation: fadeIn .4s;
}
.dropdown .dropdown-menu .dropdown-item {
    width: 100%;
    padding: .25rem 1.5rem;
    /* padding: 6px 12px; */
    margin-bottom: 2px;
    border-radius: 4px;
    font-size: 14px;
    /* color: #666; */
    text-decoration: none;
    /* display: inline-block; */
    float: left;
    letter-spacing: .5px;
    clear: both;
    position: relative;
    outline: 0;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
}
    
.dropdown-menu .dropdown-item{
    font-size: 15px;
    text-transform: capitalize;
    color: rgba(15, 13, 13, 0.9);
}
.dropdown-menu .dropdown-item:hover{
    /* background: rgb(10, 99, 233); */
    color: rgb(10, 99, 233);
    /* color: rgb(243, 245, 247); */
    border: 0;
}
    
       /* ------------------------------------------SCREEN SIZES-------------------------------------------------- */
    @media only screen and (max-width: 868px) {
		.navbar-container{	
			display: flex;
			flex-wrap: wrap;
		    justify-content: space-between;
			width: 100%;
		}
		.navbar-img{
			width: 150px;
			padding: 0 .5rem;
			margin-left:.5rem ;
			margin-right:.5rem ;
		}
		
		
	}
	@media screen and (max-width: 900px){
	
	
	}
	@media screen and (max-width: 600px) {
		.navbar-container{	
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			/* flex-wrap: wrap; */
			width: 100%;
		  /* margin-right: 10%; */
		  /* margin-left: 10%; */
		}	
	}
	@media screen and (max-width: 400px) {
		.navbar-container{	
			display: flex;
			flex-wrap: wrap;
		    justify-content: space-between;
			width: 100%;
		  /* margin-right: 10%; */
		  /* margin-left: 10%; */
		}	
		
	}
	@media screen and (max-width: 1000px) and (min-width: 868px) {
		.navbar-container{	
			display: flex;
			/* flex-wrap: wrap; */
		    justify-content: space-between;			
			/* margin-right: 0%;
			margin-left: 0%; */
			width: 100%;
		}	
	}