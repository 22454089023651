.contactUs{
    background-color: rgba(196, 197, 204, 0.2);
        width: 100%;
    /* text-align: center; */
}

.formContainer{ 
    padding: 40px;
    font-size: 24px;
    color: #252b33;
    box-shadow: 2px 2px 25px #a3a4e9;
    font-weight: 600;
    background-color: white; 
    line-height: 34px;
    margin-bottom: 20px;   
    width: 80%;
}
/* .overbox{
    left: 22%;
    height: 50%;
    width: 16%;
    box-shadow: 2px 2px 25px #a3a4e9;
    background-color: ivory;
    position: absolute;
    margin-top: 25px;
} */
.formContainer .content{
    margin-bottom: 20px;
    text-align:left;
}
.contactMargin{
    margin-top: 6rem;
}
.contact-container{
    /* background-color: rgba(161, 176, 238, 0.2); */
    padding: 20px;
}
/* .upperMargin{
    margin-top: 7vw;
} */
.heading-title {
    color: #243E8E;
    font-size: 44px;
    font-size: 2.75rem;
    font-weight: 700;
    margin-bottom: 30px;
    padding-top: 30px;
}
.office-location {
    color: #243E8E;
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0;
}

.office-country {
    color: #243E8E;
    font-size: 18px;
    font-size: 1.125rem;
    display: block;
}
.country-flag {
    margin: 15px 0;
    width: 48px;
    height: 28px;
}
.office-address {
    color: #243E8E;
    font-size: 18px;
    font-size: 1.125rem;
    display: block;
    margin-bottom: 0;
}
  .parent {
    width: 860px;
    height: 450px;
    /* width: 70vw; */
    /* height: 400px; */
    margin: 6vw 10vw;
  }
  
  .child {
    opacity: 1;
    z-index: 364;
    display: block;
    /* height: 21vw; */
    /* width: 280px; */
    background: #fff;
    position: relative;
    top:-340px;
    left: 1150px;
    text-align: left;
    box-shadow: 0 30px 50px -20px rgb(25 45 100 / 15%);
    padding: 30px 30px;
    width: 300px;
    height: auto;
}
.address-mobile-view{
    display: none;
    visibility: none;
}
.address-middle-view{
    display: none;
    visibility: none;
}
  
  

@media screen and (max-width: 600px) {

    .contactUs{
        /* padding: 20px; */
        text-align: center;
        width: 100%;
    }    
    .contactMargin{
        margin-top: 8vw;
    }
    .heading-title {
        padding-top: 50px;
    }
    .parent {
        width: 100% ;
        height: 250px;
        /* width: 70vw; */
        margin: 0px;
    }
    .child {
        display: none;
    }    
    .address-mobile-view{
        display: inline-block;
        visibility: visible;
        width: 90%;
        height: auto;
        background-color: #fff;
        position: relative;
        padding: 30px 30px;
       text-align: left;
       box-shadow: 0 30px 50px -20px rgb(25 45 100 / 15%);


    }
    .formContainer{ 
        margin-bottom: 30px;   
        width: 100%;
        padding: 30px;
    }
    
    .container{ 
        /* padding-left: 0px;
        padding-right: 0px;
        margin-left: 0px;
        margin-right: 0px; */
    }
    
}
@media  screen and (min-width: 700px) and (max-width: 1200px) {
    .parent {
        width: 100% ;
        height: 350px;
        /* width: 70vw; */
        margin: 0px;
    }
    .formContainer{ 
        width: 100%;
    }
    .address-middle-view{
        margin: 15px;
        visibility: visible;
        display: inline-block;
        justify-content: center;
        width: 100%;
        height: auto;
        background-color: #fff;
        position: relative;
        padding: 30px 30px;
       text-align: left;
       box-shadow: 0 30px 50px -20px rgb(25 45 100 / 15%);
    }
    .child{
        display: none;
    }
}
@media  screen and (max-width: 1400px) {
    .contactMargin{
        margin-top: 7vw;
    }
    
    .child {
        /* position: relative; */
        top:-380px;
        left: 750px;
    }
   
   

}