/* colors classes */


/*
--------------------------
	Css Indexing
--------------------------
** @typography
--------------------------
** Service Page css
--------------------------
** @media queries css
-------------------------- 
 */
.blue2{
    color: #0000e2
}
.black1{
    color: black;
}

.services{
    padding: 20px;
}
.lists {
    font-size: 16px;
    color: #252b33;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 25px;
    text-align: left;
}
h2{
    text-transform: uppercase;
    font-size: 30px;
    color: #252b33;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 25px;
}
h3{
    font-size: 24px;
    color: #252b33;
    font-weight: 600;
    line-height: 34px;
    margin-bottom: 10px;
}
.serviceImg{
    background-image:
    linear-gradient(to right, rgba(31, 3, 95, 0.986) 30%, transparent),
        url('/public/Images/CarouselImages/1000/digital.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    height: 18rem;
    padding-top: 4rem;
    position: relative;
    color: white;
}
.odmserviImg{
    background-image:
    linear-gradient(to right, rgba(10, 6, 20, 0.986) 30%, transparent),
        url('/public/Images/300/cloud-computing.png');
    background-size: cover;
    width: 100%;
    height: 100%;
    color: white;
}
.contentCenter {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.boxshadow{
    box-shadow: 2px 2px 20px rgb(248, 245, 245);
}

/* implementation service */
.outerRound{
    /* box-sizing: border-box; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;    
}
.customCard{
    /* box-shadow: 2px 2px 20px rgb(248, 245, 245); */
    height: 20rem;
    box-shadow: 0px 3px 16px -10px rgb(0 0 0 / 75%);
    box-sizing: content-box;
    border: 1px solid rgb(216, 213, 213);
    font-family: open sans,sans-serif;
    margin-bottom: 0px !important;
    z-index: 1;
    width: 90%;
}
.roundText{
    font-size: 16px;
    font-family: open sans,sans-serif;   
}
.roundWrapper{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    bottom: 40px;
    z-index: 2;
}
.roundWrapper h5{
    font-weight: 600;
}
.cardcontent{
    padding: 15px;
    margin-top: 4rem;
    height: auto;
}
.cardbody{
    height: 10rem;
    width: 100%;
    position: relative;
}
.btn-box{
    width: 100%;
    height: 4rem;
    position: relative;
    bottom: 0;
}
.roundBox{
    box-shadow: 0px -10px 15px -15px rgb(0 0 0 / 75%);
    width: 8rem;
    height: 8rem;    
    border-radius: 50%;
    display: flex;
    z-index: 3;
    flex-direction: row;
    /* border: 1px solid rgb(216, 213, 213); */
    justify-content: center;
    padding: 15px;
    position: absolute;
    top: 0;
    transition: top ease 0.5s ;
}
.roundBox:hover{
    top: -10px;        
}
.borderBottom{
    width: 20rem;
    text-align: center;
    /* border-bottom: 4mm ridge rgba(16, 19, 16, 0.6); */
    border-bottom: 2px solid rgb(241, 6, 143);
}
.roundContent{
    height: 5rem;
    width: 100%;
}
.roundBox::after{
    content: "\a";
    white-space: pre;
}
.roundContent::before{
    content: "\a";
    white-space: pre;
}

/* odm servicce */
.serviceData{
    /* color: #252b33 transparent; */
    line-height: 40px;
    color: rgba(250, 248, 248, 0.5), transparent;
}
.hr2{   
    width: 20rem;
    text-align: center;
    /* border-bottom: 4mm ridge rgba(16, 19, 16, 0.6); */
    border-bottom: 4px solid rgb(241, 6, 143);
}
/* thinking-women.png */
.leftImage{
    background-image: url('/public/Images/300/thinking-women.png');
    background-size: cover;
    background-repeat: no-repeat;
    width:100%;
     height: 35rem;
}

/* service align  */
.serviceAlign{
    padding-left: 12rem;
}

.warningBox{
    padding: 2.5rem;
    margin-top: 4rem;
    position: relative;
    height: auto;
    width: 100%;
    background-attachment: fixed;
    background-repeat: space;
    background-size: cover;
    background-image :linear-gradient(to right, rgba(6, 6, 7, 0.911) 30%, rgba(117, 19, 93, 0.73)),
        url('/public/Images/1000/questions.jpg');    
}
.contentText{
    font-size: 30px;
}
body{
    font-family: open sans,sans-serif;
}
.bgImage{
    background-image: url('/public/Images/300/confusedAboutODM.jpg');
    z-index: 0;
    opacity: 0.7;
    background-repeat: no-repeat;
    background-size: cover;
}

.container-bg1{
    position: relative;
    background-image: url('/public/Images/1000/bm.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1rem;
}
.container-bg2{
    position: relative;
    background-image: url('/public/Images/1000/back2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1rem;
}
.pakimg-container{
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 100%;
    background:url('/public/Images/gif/cloud-pak.gif');
    width: 100%;
    height: 100%;
}
.pak-container{
    padding: 3rem 4rem;
}
.module-border-wrap2{
    max-width: 100%;
    padding: .5rem;
    position: relative;
    background: linear-gradient(to right, rgb(128, 0, 53), rgb(57, 66, 199) );
    /* color: rgb(16, 8, 128) */
    
}
.content-container{
    padding: 30px 20px;
}

@media screen and (max-width: 400px) {


.pakimg-container{
    
    width: 100%;
    height: 100%;
}

}
@media screen and (max-width: 600px) {
    /* .contentText{
        font-size: 15px;
    } */
    .customCard{
        /* box-shadow: 2px 2px 20px rgb(248, 245, 245); */
        height: 25rem;
        box-shadow: 0px 3px 16px -10px rgb(0 0 0 / 75%);
        box-sizing: content-box;
        border: 1px solid rgb(216, 213, 213);
        margin-bottom: 0px !important;
        z-index: 1;
        /* padding: 20px; */
        width: 90%;
    }
   
    .lists {
        font-size: 16px;
        color: #252b33;
        font-weight: 400;
        line-height: 28px;
    }
    h3{
        font-size: 24px;
        color: #252b33;
        font-weight: 600;
        line-height: 34px;
    }
}