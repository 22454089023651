.footer{
    background-color:  #e9e7e1;
    /* background-color:  #0a0a0a; */
    padding: 25px;
    text-align: center;
}
.footer-column {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.footer p{
    margin-bottom: 8px;
    padding: 1rem;
    font-size: small;
    color:rgb(8, 8, 8);
}
i{
    padding-right: 17px;
    padding-left: 17px;

}
a{
    color: rgb(8, 8, 8);
    /* box-shadow: 2px 2px 2px rgb(167, 162, 162); */
}
a:hover{
    color:  rgb(223, 43, 43);;
}


.contact p{
    font-size: 16px;
    margin-top: 20%;
    /* color:rgb(8, 8, 8); */
    color:rgb(250, 248, 248);
}