.videoContainer{
    margin-left: 2%;
    margin-right: 2%;
}
.contentRow{
    margin: 5%;
}
.videobox{
    padding: 2%;
    box-shadow: 2px 2px 25px #a3a4e9;
}
.quotes{
    margin: 5%;
}
.quotes h1{
    font-size: 52px;
}