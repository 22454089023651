.careersContainer{
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 3%;
}

.careerBox{
    padding: 2%;
    background-color: rgb(250, 250, 250);
    height: 350px;
    margin: 2%;
    overflow: auto;
}
.careerBox:hover{
    box-shadow: 2px 2px 25px #a3a4e9;
   
}
.careerBox p{
    text-align: justify;
}
.jobTitle{
    margin: 2%;
    color: white;
    padding: 1%;
    background-color: rgb(223, 43, 43);
    transition: 0.3s;
    cursor: pointer;
    border-radius: 10px;
}
.jobTitle:hover{
    background-color: rgb(49, 41, 41);
}
.jobContent{
    display: block;
    margin-left: 4%;
    margin-right: 4%;
    text-align: justify;
}

/* .jobTitle:hover + .jobContent {
    margin-left: 4%;
    margin-right: 4%;
    text-align: justify;
    margin: 2%;
    display: block;  
} */
p,li{
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
}
li{
margin-left: 4%;
}
p b{
    font-size: medium;
}
.btn-apply{
    position: relative;
    background-color: rgb(223, 43, 43);
    border: 1px solid rgb(223, 43, 43);;
    padding: 10px;
    border-radius: 20px;
    text-decoration: none;
    color: white;
    font-size: 400;
    width:8%;
    text-align:center;
}

.btn-apply:hover{
    position: relative;
    /* background-color: rgb(49, 41, 41); */
    background-color: rgb(223, 43, 43);
    padding: 10px;
    border-radius: 20px;
    text-decoration: none; 
    color: white;
    font-size: 400;
    width:8%;
    text-align:center;
}

.modal-footer input[type="checkbox"] {
	background: rgb(204, 21, 15);
  }
	
  .modal-footer input[type="checkbox"]:checked {
	background: rgb(8, 172, 16);
  }

  @media screen and (max-width: 400px) {
        
    .btn-apply{
       width: 100%;
       cursor: pointer;
    }

  }